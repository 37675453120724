export enum ValidKeysFollow {
  UTM = 'utm',
  KX = 'kx'
}

export const getQueryData = (url: string) => {
  const queryString = url.split('?').slice(1).join('?');
  const paths = queryString.split('&');
  const filteredPaths = paths.filter((path) =>
    Object.values(ValidKeysFollow).some((validPath) => path.startsWith(`${validPath}_`))
  );
  const data = filteredPaths.reduce((acc, path) => {
    const [key, ...valueParts] = path.split('=');
    const value = valueParts.join('=');
    return {
      ...acc,
      [key]: value
    };
  }, {});

  return data;
};
